import './App.css';
import React, {Component} from "react";

class App extends Component {
    handleProfilePicClick = async () => {
        let blob = await fetch("ProfilePicture.png").then(r => r.blob());
        const url = window.URL.createObjectURL(
            new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `ProfilePicture.png`,
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    };
  render(){
    return (
        <div>
            <div className="header">
                <div className="title">Justmartythings</div>
                <button onClick={this.handleProfilePicClick}/>
            </div>
        </div>
    );
  }
}

export default App;
